import React from "react"

import SEO from "../../components/seo"
import CenterBreadCrumbs from "../../components/centerBreadCrumbs"

const AboutUs = () => {
  return (
    <>
      <SEO title="Lil Seeds - About Us" />

      <CenterBreadCrumbs
        centerName="Lil Seeds"
        centerUrl="/littleseeds/"
        subPageName="About Us"
      />

      <div className="container-fluid horizontal-section-container clearfix">
        <div className="row">
          <div className="col-sm-12">
            <div
              id="main-page-content"
              className="section-container main-page-content clearfix"
            >
              <div className="section-content">
                <h1 className="page_title">About Us</h1>

                <h4>Lil Seeds</h4>

                <div id="content">
                  <p>
                  Lil Seeds is the perfect place for our little people to start extending their world into early childhood education and care.  We are very aware of what this age group requires to at this foundation level, such as small learning groups, primary/key caregiving, a calm, environment, which flows at the same pace of our little people and most importantly "love".  Our ideals are backed by the scientific neuroscience and by theorists such as Pikler, and the RIE philosophy.
                  </p>

                  <p>We believe in the teina/tuakana approach. </p>

                  <p><em>"The tuakana–teina relationship, an integral part of traditional Maori society, provides a model for buddy systems. An older or more expert tuakana (brother, sister or cousin) helps and guides a younger or less expert teina (originally a younger sibling or cousin of the same gender). In a learning environment that recognises the value of ako, the tuakana–teina roles may be reversed at any time"</em> (MOE, 2017).</p>

                  <p>We value this by naming our Infants are: teina and our toddler are tuakana.  We love to spend time together, to grow together and to all feel a sense of belonging within Lil Seeds.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutUs
